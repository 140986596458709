import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-white navbar-dark bg-dark">
      <Container className="text-light">
        <Row className="py-3">
          <Col md={4}>
            <Image
              src="/images/logo.png"
              alt="logo"
              fluid="true"
              className="logoFooter"
            />
            <div className="my-3">
              <div className="mb-3">
                <Link to="/find-us/lampa">
                  <i className="fas fa-map-marker-alt pr-2"></i>
                  Jirón Lampa 1062
                </Link>
              </div>
              <div className="mb-2">
                <a href="tel:014279902" target="_blank" rel="noreferrer">
                  <i className="fas fa-phone pr-2"></i>
                  01 4279902
                </a>
              </div>
              <div className="mb-2">
                <a
                  href="https://wa.link/luglww"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp pr-2"></i>
                  981102407
                </a>
              </div>
              <div className="mb-2">
                <a
                  href="https://wa.link/y5v625"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp pr-2"></i>
                  977776527
                </a>
              </div>
              <hr className="bg-light" />
              <div className="mb-2">
                <a
                  href="mailto:salomonpacificosac@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fas fa-envelope pr-2"></i>
                  salomonpacificosac@gmail.com
                </a>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <Image
              src="/images/logo.png"
              alt="logo"
              fluid="true"
              className="logoFooter"
              style={{ visibility: "hidden" }}
            />
            <div className="my-3">
              <div className="mb-3">
              <Link to="/find-us/paruro">
                <i className="fas fa-map-marker-alt pr-2"></i>
                Jirón Paruro 1132 Stand 142
                </Link>
              </div>
              <div className="mb-2">
                <a href="tel:014261118" target="_blank" rel="noreferrer">
                  <i className="fas fa-phone pr-2"></i>
                  01 4261118
                </a>
              </div>
              <div className="mb-2">
                <a
                  href="https://wa.link/ky3zfm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp pr-2"></i>
                  981102420
                </a>
              </div>
              <div className="mb-2" style={{ visibility: "hidden" }}>
                <a
                  href="https://wa.link/ky3zfm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp pr-2"></i>
                  981102420
                </a>
              </div>
              <hr className="bg-light" />
              <div className="mb-2">
                <a
                  href="mailto:salomonpacificosac@hotmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fas fa-envelope pr-2"></i>
                  salomonpacificosac@hotmail.com
                </a>
              </div>
            </div>
            {/* <h5>Contacto</h5>
            <hr className="bg-light" />
            <div className="my-2">
              <MapContainer
                center={[-12.054208809262152, -77.0255821313492]}
                zoom={17}
                scrollWheelZoom={false}
                style={{ height: "15rem" }}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[-12.054208809262152, -77.0255821313492]}>
                  <Popup>
                    <Row>Distribuidora Salomón Pacífico.</Row>
                    <Row className="my-2">
                      <Col className="text-center">
                        <a
                          href="https://www.google.com.pe/maps/place/Distribuidora+Salom%C3%B3n+Pac%C3%ADfico/@-12.0545072,-77.0258617,17.16z/data=!4m8!1m2!2m1!1ssalomon+pacifico!3m4!1s0x9105c8ba4dea66df:0x241903e0017d4842!8m2!3d-12.0542129!4d-77.0255859"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "green" }}
                        >
                          <i
                            className="fas fa-map-marker-alt py-2"
                            style={{ fontSize: "25px" }}
                          ></i>
                          <br />
                          Google Maps
                        </a>
                      </Col>
                      <Col className="text-center">
                        <a
                          href="https://waze.com/ul?ll=-12.05429650%2C-77.02558120&navigate=yes"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="fab fa-waze py-2"
                            style={{ fontSize: "25px" }}
                          ></i>
                          <br />
                          Waze
                        </a>
                      </Col>
                    </Row>
                  </Popup>
                </Marker>
              </MapContainer>
            </div> */}
          </Col>
          <Col md={4}>
            <Image
              src="/images/logo.png"
              alt="logo"
              fluid="true"
              className="logoFooter"
              style={{ visibility: "hidden" }}
            />
            <div className="mb-3">
              <h5 className="my-3">¡Encuéntranos!</h5>
              <div className="my-2 social-network">
                <Row>
                  <a
                    href="https://www.facebook.com/Distribuidormayoristasalomon"
                    target="_blank"
                    rel="noreferrer"
                    className="mx-1 ml-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "cornflowerblue",
                    }}
                  >
                    <i
                      className="fab fa-facebook py-2 mr-2"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </a>
                  {/* <a
                    href="https://waze.com/ul?ll=-12.05429650%2C-77.02558120&navigate=yes"
                    target="_blank"
                    rel="noreferrer"
                    className="mx-1 ml-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "pink",
                    }}
                  >
                    <i
                      className="fab fa-instagram py-2 mr-2"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </a>
                  <a
                    href="https://waze.com/ul?ll=-12.05429650%2C-77.02558120&navigate=yes"
                    target="_blank"
                    rel="noreferrer"
                    className="mx-1 ml-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "cornflowerblue",
                    }}
                  >
                    <i
                      className="fab fa-twitter py-2 mr-2"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </a> */}
                  <a
                    href="https://wa.link/luglww"
                    target="_blank"
                    rel="noreferrer"
                    className="mx-1 ml-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "green",
                    }}
                  >
                    <i
                      className="fab fa-whatsapp py-2 mr-2"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </a>
                  {/* <a
                    href="https://waze.com/ul?ll=-12.05429650%2C-77.02558120&navigate=yes"
                    target="_blank"
                    rel="noreferrer"
                    className="mx-1 ml-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <i
                      className="fab fa-youtube py-2 mr-2"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </a> */}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center py-3">
            Copyright &copy; GIK Digital {new Date().getFullYear()}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
