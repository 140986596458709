import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Row, Col, Card, Container } from "react-bootstrap";
import BannerCarousel from "../components/BannerCarousel";
import ProductCarousel from "../components/ProductCarousel";
import BrandMarquee from "../components/BrandMarquee";

const HomeScreen = () => {
  return (
    <>
      <BannerCarousel />
      <Container>
        <h2 className="mt-5 mb-3 secondary-blue bold text-center">¿Qué Deseas Remodelar?</h2>
        <Row>
          <Col md={3}>
            <LinkContainer
              className="CardLinkContainer"
              to="/shop?section=COCINA"
            >
              <Card className="my-3 p-3 rounded w100p mh-400">
                <Card.Title>
                  <h4 className="dark-blue bold text-center">Cocina</h4>
                </Card.Title>
                <Card.Img
                  className="mh-210 of-ct"
                  src="/images/cocina.jpg"
                  variant="top"
                  fluid="true"
                />

                <Card.Body>
                  <Card.Text as="p" className="dark-blue">
                    Renueva tu cocina con los mejores accesorios.
                  </Card.Text>
                  <Card.Text as="p" className="dark-blue text-center">
                    Ver Más
                  </Card.Text>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>
          <Col md={3}>
            <LinkContainer
              className="CardLinkContainer"
              to="/shop?section=BAÑO"
            >
              <Card className="my-3 p-3 rounded w100p mh-400">
                <Card.Title>
                  <h4 className="dark-blue bold text-center">Baño</h4>
                </Card.Title>
                <Card.Img
                  className="mh-210 of-ct"
                  src="/images/bano.jpg"
                  variant="top"
                  fluid="true"
                />

                <Card.Body>
                  <Card.Text as="p" className="dark-blue">
                    Diseña tu baño con las mejores marcas del mercado.
                  </Card.Text>
                  <Card.Text as="p" className="dark-blue text-center">
                    Ver Más
                  </Card.Text>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>
          <Col md={3}>
            <LinkContainer
              className="CardLinkContainer"
              to="/shop?section=GASFITERIA"
            >
              <Card className="my-3 p-3 rounded w100p mh-400">
                <Card.Title>
                  <h4 className="dark-blue bold text-center">Gasfitería</h4>
                </Card.Title>
                <Card.Img
                  className="mh-210 of-ct"
                  src="/images/gasfiteria.jpg"
                  variant="top"
                  fluid="true"
                />

                <Card.Body>
                  <Card.Text as="p" className="dark-blue">
                    Encuentra todo lo que necesitas en accesorios para tu hogar.
                  </Card.Text>
                  <Card.Text as="p" className="dark-blue text-center">
                    Ver Más
                  </Card.Text>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>
          <Col md={3}>
            <LinkContainer
              className="CardLinkContainer"
              to="/shop?section=LAVANDERIA"
            >
              <Card className="my-3 p-3 rounded w100p mh-400">
                <Card.Title>
                  <h4 className="dark-blue bold text-center">Lavandería</h4>
                </Card.Title>
                <Card.Img
                  className="mh-210 of-ct"
                  src="/images/lavanderia.jpg"
                  variant="top"
                  fluid="true"
                />

                <Card.Body>
                  <Card.Text as="p" className="dark-blue">
                    Te asesoramos en remodelar tu lavandería con las mejores
                    marcas.
                  </Card.Text>
                  <Card.Text as="p" className="dark-blue text-center">
                    Ver Más
                  </Card.Text>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>
        </Row>
        <h2 className="mt-5 mb-3 secondary-blue bold text-center">Productos Destacados</h2>
        <ProductCarousel />
        <h2 className="mt-5 mb-3 secondary-blue bold text-center">Marcas que nos respaldan</h2>
        <BrandMarquee />
      </Container>
    </>
  );
};

export default HomeScreen;
