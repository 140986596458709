import React from "react";
import { Row, Col, Card, Container, Image } from "react-bootstrap";

const AboutUsScreen = () => {
  return (
    <Container>
      <h1 className="secondary-blue bold text-center mt-5 mb-3 text-center">
        La Empresa
      </h1>
      <Image
        src="/images/nosotros.jpg"
        alt="nosotros"
        fluid="true"
        className="about-us-img"
      />
      <Row>
        <h4>Distribución Mayorista A Nivel Nacional</h4>
        <p>
          Contamos con más de 25 años de experiencia en el rubro de gasfitería,
          desde 1995 nos hemos posicionado como uno de los distribuidores
          especialistas líderes a nivel nacional.
          <br />
          <br />
          Actualmente contamos con 2 sedes en lima, ubicadas en el centro de
          lima. Tenemos una distribución propia y con personal altamente
          capacitado en gasfiteria. Nos respaldan las mejores marcas del mercado
          en el rubro como: Vainsa, Cimberio, Italgrif, Coflex, Metusa, Oatey,
          entre otros.
        </p>
      </Row>
      <Row>
        <h4>¿Por Qué elegirnos?</h4>
      </Row>
      <Row>
        <ul>
          <li>
            Tenemos una amplia variedad de categorías con marcas atractivas.
          </li>
          <li>Buena relación calidad-precio en productos.</li>
          <li>Entregas a nivel nacional</li>
          <li>Garantía post-venta.</li>
          <li>Respaldo de las mejores marcas en el rubro.</li>
        </ul>
      </Row>
      <h1 className="secondary-blue bold text-center text-center mt-5">
        Conózcanos
      </h1>
      <Row>
        <Col md={3}>
          <Card className="my-3 p-3 rounded w100p">
            <Card.Title>
              <h4 className="text-center">Equipo de Ventas - Lampa</h4>
            </Card.Title>
            <Card.Img
              src="/images/ventas.png"
              variant="top"
              fluid="true"
              style={{ width: "50%", margin: "0 auto" }}
            />
            <Card.Body>
              <Card.Text as="p" className="text-center">
                Contáctenos
              </Card.Text>
              <Card.Text>
                <Row className="mt-2">
                  <Col className="text-center">
                    <a
                      href="https://wa.me/+51981102407"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "green" }}
                    >
                      <i
                        className="fab fa-whatsapp pt-2"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </a>
                  </Col>
                  <Col className="text-center">
                    <a
                      href="mailto:salomonpacificosac@gmail.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fas fa-envelope pt-2"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </a>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="my-3 p-3 rounded w100p">
            <Card.Title>
              <h4 className="text-center">Atención al Cliente - Lampa</h4>
            </Card.Title>
            <Card.Img
              src="/images/cliente.png"
              variant="top"
              fluid="true"
              style={{ width: "50%", margin: "0 auto" }}
            />

            <Card.Body>
              <Card.Text as="p" className="text-center">
                Contáctenos
              </Card.Text>
              <Card.Text>
                <Row className="mt-2">
                  <Col className="text-center">
                    <a
                      href="https://wa.me/+51977776527"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "green" }}
                    >
                      <i
                        className="fab fa-whatsapp pt-2"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </a>
                  </Col>
                  <Col className="text-center">
                    <a
                      href="mailto:salomonpacificosac@gmail.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fas fa-envelope pt-2"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </a>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="my-3 p-3 rounded w100p">
            <Card.Title>
              <h4 className="text-center">Equipo de Ventas - Paruro</h4>
            </Card.Title>
            <Card.Img
              src="/images/ventas.png"
              variant="top"
              fluid="true"
              style={{ width: "50%", margin: "0 auto" }}
            />

            <Card.Body>
              <Card.Text as="p" className="text-center">
                Contáctenos
              </Card.Text>
              <Card.Text>
                <Row className="mt-2">
                  <Col className="text-center">
                    <a
                      href="https://wa.me/+51981102420"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "green" }}
                    >
                      <i
                        className="fab fa-whatsapp pt-2"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </a>
                  </Col>
                  <Col className="text-center">
                    <a
                      href="mailto:salomonpacificosac@hotmail.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fas fa-envelope pt-2"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </a>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="my-3 p-3 rounded w100p">
            <Card.Title>
              <h4 className="text-center">Atención al Cliente - Paruro</h4>
            </Card.Title>
            <Card.Img
              src="/images/cliente.png"
              variant="top"
              fluid="true"
              style={{ width: "50%", margin: "0 auto" }}
            />

            <Card.Body>
              <Card.Text as="p" className="text-center">
                Contáctenos
              </Card.Text>
              <Card.Text>
                <Row className="mt-2">
                  <Col className="text-center">
                    <a
                      href="https://wa.me/+51981102420"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "green" }}
                    >
                      <i
                        className="fab fa-whatsapp pt-2"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </a>
                  </Col>
                  <Col className="text-center">
                    <a
                      href="mailto:salomonpacificosac@hotmail.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fas fa-envelope pt-2"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </a>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUsScreen;
